import React from "react";
import { WhatsappSupportUrl } from "@/utils/constants";

const WhatsAppSupportButton = () => {
  return (
    <div className="fixed bottom-4 right-5 z-50">
      <a href={WhatsappSupportUrl} target="_blank">
        <img
          src="/images/whatsapp-icon.svg"
          alt="WhatsApp"
          className="h-auto w-12 border-none"
        />
      </a>
    </div>
  );
};

export default WhatsAppSupportButton;
