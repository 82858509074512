interface FAQItemType {
  a: string;
  q: string;
}

export const faqData: FAQItemType[] = [
  {
    q: "האם סמאפי עובדת עם כל חברות הביטוח?",
    a: "כן! סמאפי מבצעת השוואת מחירים מול כל חברות הביטוח הגדולות (הראל, פניקס, מגדל, איילון, כלל, הכשרה ומנורה) בהתאם לפרופיל הלקוח ולתנאי הביטוח שצריך. ההשוואה מתבצעת בין כל החברות וגם בין עשרות המסלולים שכל חברה מציע ומציגה את התוצאות הטובות ביותר שהתקבלו.",
  },
  {
    q: "האם ההשוואה נעשית מול כל חברות הביטוח?",
    a: "כן! ההשוואה נעשית בדרך כלל מול שבע חברות ביטוח ומציגה את ההצעות הזולות ביותר בהתאם לביטוח הדרוש ולפרופיל שלך כלקוח.",
  },
  {
    q: "מה יקרה מיד לאחר התשלום?",
    a: "מיד לאחר התשלום, תקבל מייל עם אישור רכישת הביטוח. במידה ואין כל בעיה רפואית, תקבל גם את פוליסת הביטוח שלך. בנוסף, ישלח לך קישור לאזור האישי שלך, בו תוכל להתעדכן בסטטוס רכישת הביטוח. לדו'ג אם חברת הביטוח תצטרך ממך מסמך כלשהו, תוכל לראות זאת באיזור האישי וגם נעדכן במייל ובהודעת וואטסאפ לנוחיות מירבית. בנוסף לכל זה, סמאפי ממנה מומחה ביטוח שידאג להיות איתך בקשר לכל שאלה או בקשה.",
  },
  {
    q: "איך המחיר שלכם כזה זול לעומת חברות וסוכני ביטוח?",
    a: "המחיר שלנו נמוך כל כך בגלל השימוש במערכות הטכנולוגיות שפיתחנו, המאפשרות לנו לעבוד ביעילות גבוהה מאוד מול חברות הביטוח ולחסוך להן בעלויות הכרוכות בהפקת הביטוח, כך הגענו למצב שחלק מהחיסכון הזה עובר אליכם וכולם מרוצים :) הסיבה השנייה היא שיש לנו אפשרות לבצע השוואת מחירים ותנאי ביטוח בין כל חברות הביטוח ובין כל המסלולים הקיימים לכל חברה בהינף כפתור.",
  },
  {
    q: "כמה כסף סמאפי יכולה לחסוך לי?",
    a: "במילה אחת - המון! החיסכון תלוי באיזה ביטוח מדובר ומה הגיל שלכם. על פי הנתונים של סמאפי, ההבדל בין המחיר שתקבלו בחברות ביטוח, סוכני ביטוח או בבנק יכול להגיע לכ- מאות אלפי ₪ בעלות הביטוח המצטברת אותם תוכלו לחסוך לאחר רכישת ביטוח בסמאפי. תוסיפו לזה את הזמן והטרטור שנחסך לכם בשיחות עם נציגי שירות, פגישות והחסכון עצום!",
  },
  {
    q: "איך סמאפי מצליחה לחסוך לכם?",
    a: "סמאפי מבצעת השוואת מחירים מול חברות הביטוח ומציגה לכם את ההצעות המשתלמות ביותר גם מבחינת תוכן הפוליסה וגם מבחינת המחיר. למרות שמראש אנו מציגים את ההצעות הטובות ביותר, ישנם מקרים חריגים בהם תוכלו לראות הצעה זולה יותר משראיתם בסמאפי, כמובן שאנחנו לא נכנעים! במקרה כזה, אנו נבצע מול חברות הביטוח מיקוח עד שנשיג לכם את ההצעה הזולה והטובה ביותר שאתה כלקוח יכול לקבל. וכדי שתהיה מרוצה עד הסוף, במידה ולא הצלחנו להשוות, נפנק אותך בשירות מעקב אחרי עלות הביטוח ללא עלות! הטכנולוגיה שפיתחנו מאפשרת לנו להבין מהן ההצעות הזולות ביותר שלקוח כמוכם יכול לקבל, ולכן אנו יכולים לנהל מכרז חכם מול חברות הביטוח, שבסופו אנו מגיעים להצעה הזולה ביותר.",
  },
  {
    q: "מה קורה אם יש לי כבר ביטוח קיים?",
    a: "אין שום בעיה! אנו נדאג לבצע לכם השוואת מחיר ותנאי ביטוח להצעה הקיימת לכם, נשקף לכם את הנתונים בצורה שקופה, פשוטה ומובנת כדי שתקבלו החלטה מושכלת וגם נדאג לבטל לכם את הביטוח הקיים.",
  },
  {
    q: "מה קורה אם אצליח להשיג הצעה טובה יותר מההצעה הזולה ביותר שסמאפי השיגה לי?",
    a: "סמאפי מתחייבת להשיג לך את ההצעה הטובה ביותר מחברות הביטוח. אם במקרה הצלחתם לשפר את ההצעה הטובה ביותר שאנחנו השגנו לך עם אותם התנאים, תוכלו לקבל שירות מעקב אחרי הביטוח ללא עלות! כן אנו עד כדי כך בטוחים בעצמנו :)",
  },
  {
    q: "האם חברות הביטוח משלמות לכם?",
    a: "כן, בהתאם להוראות חוק הפיקוח על הביטוח, אנו מקבלים תשלום מחברת הביטוח ולא גובים ממכם אפילו אגורה אחת. כדי להבטיח שתמיד תקבלו את ההצעות המשתלמות והשקופות ביותר, יצרנו מודל בו התגמול שאנו מקבלים מחברות הביטוח הוא זהה ואחיד. מודל זה מבטיח שתקבלו הצעות אובייקטיביות וללא שום אינטרס מצידנו להמליץ לכם על חברה לא משתלמת ממנה תאורטית יכלנו לקבל תגמול גבוה יותר.",
  },
  {
    q: "אני יכול לרכוש ביטוח לבד דרך חברת ביטוח או דרך סוכן למה לי להשתמש בסמאפי?",
    a: "סמאפי דואגת לאינטרסים שלך – שתשלם כמה שפחות על הביטוח. חברת הביטוח לעומת זאת פועל במטרה אחרת לגמרי – למקסם את הרווחים שלה. סמאפי היא גורם אובייקטיבי שמשווה בין מספר חברות ביטוח ועשרות מסלולים ומתחייבת להשיג לך את ההצעה הזולה ביותר. בגלל מודל התגמול האובייקטיבי שלנו, הטכנולוגיה ומעקב אחרי מחיר ותנאי הביטוח שלכם למשך כל חיי הביטוח מבטיחים שרכישת ביטוח בסמאפי היא המשתלמת ביותר.",
  },
  {
    q: "אני לא אוהב כל כך לשתף מידע אישי באינטרנט",
    a: "אין דבר חשוב יותר מהביטחון שלכם. לכן אנחנו בסמאפי מתחייבים שהמידע שלכם יישמר בדיסקרטיות מוחטת, באתר מאובטח, ולא יימסר לאף אחד ללא הסכמתכם. חשוב שתדעו שהמידע שלכם מוצפן ושלאחר סיום הטיפול, המידע עובר תהליך החשכה, כך שלא ניתן לשחזר את הפרטים המזהים שלכם וגם לא להצליב נתונים כדי להסיק מי אתם. המערכות בהן אנו עושים שימוש, עומדות בתקנות אבטחת מידע המחמירות ביותר ממש כמו בתעשיית הבריאות האמריקאית. ואנחנו עומדים בתקנות ה- GDPR האירופאיות, לא כי יש לנו חובה חוקית, אלא כי יש לנו חובה כלפיכם.",
  },
];
