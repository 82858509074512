import { api } from "@/utils/api";
import { Star } from "lucide-react";
import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import React from "react";
import { ArrowLeft } from "lucide-react";
import { useTranslation } from "next-i18next";
export const GoogleBusinessReviews = () => {
  const { data: reviews } = api.misc.getGooglePlaceReviews.useQuery(undefined, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  // console.log(`muly:GoogleBusinessReviews`, { reviews });

  interface Settings {
    dots: boolean;
    infinite: boolean;
    arrows: boolean;
    speed: number;
    slidesToShow: number;
    slidesToScroll: number;
    initialSlide: number;
    responsive: {
      breakpoint: number;
      settings: {
        slidesToShow: number;
        slidesToScroll: number;
        infinite: boolean;
        centerMode?: boolean;
        arrows?: boolean;
      };
    }[];
  }

  const settings: Settings = {
    dots: true,
    infinite: false,
    arrows: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
        },
      },
    ],
  };

  const slider = React.useRef<Slider>(null);
  const { t } = useTranslation("common");
  return (
    <div className="bg-[#E7F3F3] pb-16 md:pb-11">
      <div className="container mx-auto px-0 sm:px-8">
        <div className="flex h-full w-full max-w-full flex-col items-center justify-center">
          <h1 className="mb-8 mt-5 font-bold md:text-4xl">
            {t("reviews.header")}
          </h1>
        </div>
        <div className=" dir-ltr md:px-10">
          <Slider {...settings}>
            {reviews &&
              reviews.map((review, index) => (
                <div className="px-2" key={index}>
                  <div className=" rounded-md bg-gradient-to-r from-[#0e5d89] to-[#16cfc5] p-4 pt-5">
                    <img
                      src={review.profile_photo_url}
                      className="mx-auto mb-[-40px] w-20 rounded-full border-4 border-white"
                      alt="profile photo"
                    />
                    <div className="rounded-md bg-white px-4 pb-4 pt-14 text-center">
                      <div className="flex items-center justify-center">
                        {(() => {
                          const arr = [];
                          for (let i = 0; i < review.rating; i++) {
                            arr.push(
                              <svg
                                key={i}
                                className="m-0 h-4 w-4 text-[#050505]"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 22 20"
                              >
                                <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                              </svg>
                            );
                          }
                          return arr;
                        })()}
                      </div>
                      <p className="mt-5 text-sm">{review.text}</p>
                      <div className="mt-4 flex justify-between">
                        <div>
                          <p className="font-[10px] font-bold">
                            {review.author_name}
                          </p>
                          <p className="font-[8px]">
                            {review.relative_time_description}
                          </p>
                        </div>
                        <img src="/images/google.svg" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};
